import React, { Fragment, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Refresh_icon from "../../../assets/icons/refresh-icon.svg";
import Upload_icon from "../../../assets/icons/upload-venue.svg";
import Download_icon from "../../../assets/icons/download-venue.svg";
import Venue_icon from "../../../assets/icons/venue-icon.svg";
import Edit_icon from "../../../assets/icons/Edit.svg";
import Button from "../../../components/shared/UI/Button/Button";
import * as actions from "../../../store/actions/index";
import NavigationHeader from "../../../components/navigation/NavigationHeader/NavigationHeader";
import { AgGridReact } from "ag-grid-react";
import "./VenueAddressStatus.css";
import VenueModal from "../../../components/modals/venueModal/VenueModal.js";
import { AddPinsModal } from "../../../components/modals/addPinsModal/AddPinsModal";
import "./VenueAddressStatus.css";
import Backdrop from "../../../components/shared/UI/Backdrop/Backdrop";
import "../../../components/shared/CommonCss/CommonCss.css";
import {
  venueColumnDefs,
  Venue_PinsLookupTableColumnDefs,
} from "../../../constants/ColumnHeaderArray";
import { TabComponent } from "../../../components/shared/UI/TabComponent/TabComponent";
import { Venue_Tabs_Array } from "../../../constants/TabArray";
import { venue_page_constants } from "../../../constants/PageConstants";
import { replaceNullWithEmptyString } from "../../../components/shared/utility";
import NotificationBarComponent from "../../../components/shared/UI/NotificationBarComponent/NotificationBarComponent";
import { ConfirmationModal } from "../../../components/shared/UI/ConfirmationModal/ConfirmationModalPopup";
import Modal from "../../../components/shared/UI/common/Modal.js";
import Loader from "../../../components/shared/UI/Loader/Loader.js";
import { UPLOAD_MESSAGE } from "../../../constants/PageConstants.js";

const VenueAddressStatus = (props) => {
  const gridRef = useRef(null);
  const {
    currentUserRole,
    currentUser,
    onGetvenueAddressStatus,
    venueAddressData,
    token,
    onGetAllSIP,
    allSIP,
    fetchStudioTruckListSuccess,
    onFetchStudioTruckList,
    onFetchStudioTruckTypes,
    onDownloadvenueAddressDetails,
    onUploadvenueAddress,
    signalMsg,
    showModal,
    onHideModal,
    pinModalInfo,
    fetchPinModalInfo,
    onDeletePinInfo,
    onSubmitAddPinModal,
    onUpdateAddPinModal,
    onDeleteStudioTruck,
    loading,
  } = props;
  const [localvenueAddressData, setLocalvenueAddressData] = useState([]);
  const [showVenueForm, setShowVenueForm] = useState(false);
  const [studioName, setStudioName] = useState(null);
  const [studioCreatedOn, setStudioCreatedOn] = useState(null);
  const [studioTypeName, setStudioTypeName] = useState(null);
  const [studioHourRate, setStudioHourRate] = useState(null);
  const [stIfb1Pin, setStIfb1Pin] = useState(null);
  const [stIfb2Pin, setStIfb2Pin] = useState(null);
  const [stPlPin, setStPlPin] = useState(null);
  const [stLiveshotName, setStLiveshotName] = useState("");
  const [stPinsTabIfb1Pin, setStPinsTabIfb1Pin] = useState(null);
  const [stPinsTabIfb2Pin, setStPinsTabIfb2Pin] = useState(null);
  const [stPinsTabPlPin, setStPinsTabPlPin] = useState(null);
  const [stCity, setStCity] = useState(null);
  const [stState, setStState] = useState(null);
  const [stCountry, setStCountry] = useState(null);
  const [stSkype, setStSkype] = useState(null);
  const [stQuickLink, setStQuickLink] = useState(null);
  const [stWebOther, setStWebOther] = useState(null);
  const [stTxPath, setStTxPath] = useState(null);
  const [studioNotes, setStudioNotes] = useState(null);
  const [stDesc, setStDesc] = useState(null);
  const [poNum, setPoNum] = useState(null);
  const [stId, setStId] = useState(null);
  const [stLiveshotNote, setStLiveshotNote] = useState("");
  const [pinRowStId, setPinRowStId] = useState(null);

  const [activeTab, setActiveTab] = useState(Venue_Tabs_Array[0].label);
  const [isAddVenue, setIsAddVenue] = useState(false);
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const [isPinsAddMode, setIsPinsAddMode] = useState(false);
  const [currentPinId, setCurrentPinId] = useState("");
  const [currentRowVenueId, setCurrentRowVenueId] = useState("");
  const [pinsCreatedOn, setPinsCreatedOn] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorBar, setShowErrorBar] = useState(false);
  const [isDeleteIconClicked, setIsDeleteIconClicked] = useState(false);
  const [confirmationDeleteMessage, setConfirmationDeleteMessage] =
    useState(null);
  const [studioContactDetails, setStudioContactDetails] = useState({});

  useEffect(() => {
    fetchPinModalInfo(token);
  }, [token]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const allParams = {};
    for (let param of query.entries()) {
      allParams[param[0]] = param[1];
    }
    if (allParams.stId) {
      setStId(allParams.stId);
      setShowVenueForm(true);
    }
  }, [window.location.search]);

  let roleRedirect = null;
  if (currentUserRole && ![1, 5].includes(currentUserRole.roleId)) {
    roleRedirect = <Redirect to="/" />;
  }

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }
  useEffect(() => {
    onGetAllSIP(token);
    onGetvenueAddressStatus(token);
  }, [token, onGetvenueAddressStatus, onGetAllSIP]);
  useEffect(() => {
    onFetchStudioTruckTypes(token);
  }, [onFetchStudioTruckTypes, token]);

  useEffect(() => {
    if (venueAddressData && venueAddressData.length > 0) {
      var selectedDl = venueAddressData.filter((ele) => {
        return ele.softDelete === false;
      });
      const localvenueAddress_RowData = replaceNullWithEmptyString(selectedDl);
      setLocalvenueAddressData(localvenueAddress_RowData);

      if (stId) {
        var selectedSt = selectedDl.filter((el) => {
          return el.stId == stId;
        })[0];
        if (selectedSt !== undefined) {
          setStudioCreatedOn(selectedSt.createdOn);
          setStudioName(selectedSt.studioName);
          setStudioTypeName(selectedSt.studioTypeName);
          setStudioHourRate(selectedSt.studioHourRate);
          setStudioNotes(selectedSt.studioNotes);
          setStIfb1Pin(selectedSt.studioIfb1pin);
          setStIfb2Pin(selectedSt.studioIfb2pin);
          setStPlPin(selectedSt.studioPlpin);
          setStCity(selectedSt.city);
          setStState(selectedSt.region);
          setStCountry(selectedSt.country);
          setStSkype(selectedSt.skype);
          setStQuickLink(selectedSt.quickLink);
          setStWebOther(selectedSt.webOther);
          setPoNum(selectedSt.poNumber);
          setStTxPath(selectedSt.studioTx);
          setStDesc(selectedSt.studioDesc);
          setStLiveshotNote(selectedSt.liveshotNote);

          setStudioContactDetails({
            studioContact: selectedSt.studioContact,
            studioPhone: selectedSt.studioPhone,
            studioEmail: selectedSt.studioEmail,
            studioContact2: selectedSt.studioContact2,
            studioPhone2: selectedSt.studioPhone2,
            studioEmail2: selectedSt.studioEmail2,
            studioContact3: selectedSt.studioContact3,
            studioPhone3: selectedSt.studioPhone3,
            studioEmail3: selectedSt.studioEmail3,
          });
        }
      }
    }
  }, [
    venueAddressData,
    stId,
    allSIP,
    fetchStudioTruckListSuccess,
    onGetvenueAddressStatus,
    showVenueForm,
  ]);

  const editRows = (stId) => {
    setIsAddVenue(false);
    if (stId) {
      setStId(stId);
      setShowVenueForm(true);
    }
  };
  useEffect(() => {
    if (pinRowStId) {
      var selectedRow = pinModalInfo.filter((el) => {
        return el.id == pinRowStId;
      })[0];
      setStLiveshotName(selectedRow.liveshotName);
      setStPinsTabIfb1Pin(selectedRow.studioIfb1pin);
      setStPinsTabIfb2Pin(selectedRow.studioIfb2pin);
      setStPinsTabPlPin(selectedRow.studioPlpin);
      setPinsCreatedOn(selectedRow.createdOn);
    }
  }, [pinModalInfo, pinRowStId]);
  const editPinsTabRows = (stId) => {
    if (stId) {
      setPinRowStId(stId);
      setIsPinModalOpen(true);
      setIsPinsAddMode(false);
    }
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    onUploadvenueAddress(token, fileUploaded, activeTab, userId);
    event.target.value = null;
  };

  const onRefreshControlTracker = () => {
    setLocalvenueAddressData([]);
    onGetvenueAddressStatus(token);
  };

  const deleteStudio = () => {
    onFetchStudioTruckList(token);
    setShowVenueForm(false);
    resetForm();
  };
  const closeModal = () => {
    setShowVenueForm(false);
    closeErrorBar();
    resetForm();
  };

  const resetForm = () => {
    setStudioName("");
    setStudioTypeName("");
    setStudioHourRate("");
    setStudioNotes("");
    setStIfb1Pin("");
    setStIfb2Pin("");
    setStPlPin("");
    setStCity("");
    setStState("");
    setStCountry("");
    setStSkype("");
    setStQuickLink("");
    setStWebOther("");
    setStTxPath("");
    setStDesc(" ");
    setPoNum("");
    setStLiveshotNote("");
    setStudioContactDetails({});
  };
  const resetPinModal = () => {
    setStLiveshotName("");
    setStPinsTabIfb1Pin("");
    setStPinsTabIfb2Pin("");
    setStPinsTabPlPin("");
  };

  const addVenue = () => {
    setIsAddVenue(true);
    resetForm();
    setShowVenueForm(true);
    setStId("");
  };

  let modal = null;
  if (showModal) {
    let content;
    let isSuccess = [200, 201].includes(signalMsg.statusCode);
    if (isSuccess) {
      content = UPLOAD_MESSAGE.SUCCESS;
    } else if (signalMsg.statusCode === 202) {
      content = UPLOAD_MESSAGE.INVALID;
    } else {
      content = UPLOAD_MESSAGE.FAILED;
    }
    modal = (
      <Modal
        showModal={showModal}
        onModalClose={onHideModal}
        title={isSuccess ? "Upload Successful" : "Error"}
        content={content}
        type={isSuccess ? "success" : "error"}
      />
    );
  }

  const deleteIconRenderer = (params) => {
    return (
      <i
        className="fas fa-trash trashIcon"
        title="Delete"
        data-testid="delete-icon"
        onClick={(event) => handleVenueTrashIcon(params.data.stId)}
      />
    );
  };
  const deletePinIconRenderer = (params) => {
    return (
      <i
        className="fas fa-trash trashIcon"
        data-testid="delete-icon"
        title="Delete"
        onClick={(event) => handleTrashIcon(params.data.id)}
      />
    );
  };
  const editPinIconRenderer = (params) => {
    return (
      <img
        type="button"
        value="Edit"
        onClick={(event) => {
          editPinsTabRows(params.data.id);
        }}
        alt="Edit button"
        src={Edit_icon}
        className="editIcon"
        data-testid="edit-icon"
      />
    );
  };
  const editIconRenderer = (params) => {
    return (
      <img
        type="button"
        value="Edit"
        onClick={(event) => editRows(params.data.stId)}
        alt="Edit button"
        src={Edit_icon}
        className="editIcon"
        data-testid="edit-icon"
      />
    );
  };

  const handleVenueTrashIcon = (params) => {
    setIsDeleteIconClicked(true);
    setConfirmationDeleteMessage(
      venue_page_constants.VENUE_DELETE_CONFIRMATION_MESSAGE
    );
    setCurrentRowVenueId(params);
  };
  const handleTrashIcon = (params) => {
    setIsDeleteIconClicked(true);
    setConfirmationDeleteMessage(
      venue_page_constants.PIN_DELETE_CONFIRMATION_MESSAGE
    );
    setCurrentPinId(params);
  };

  const deleteVenueRow = () => {
    onDeleteStudioTruck(token, currentRowVenueId);
    // Comment out for now- redunant call-- will remove in future once app is stable
    // onFetchStudioTruckList(token);
    setCurrentRowVenueId("");
    setIsDeleteIconClicked(false);
    // Comment out for now- redunant call-- will remove in future once app is stable
    // onGetvenueAddressStatus(token);
  };
  const deletePinRow = () => {
    onDeletePinInfo(token, currentPinId);
    setCurrentPinId("");
    setIsDeleteIconClicked(false);
  };
  const cancelDelete = () => {
    setIsDeleteIconClicked(false);
  };

  let columnDefs = venueColumnDefs;
  let defaultColDef = {
    // flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: true,
    cellStyle: { textAlign: "left" },
  };

  let rowData = localvenueAddressData;
  if (activeTab === venue_page_constants.PIN_LOOKUP_TABLE_TAB) {
    columnDefs = Venue_PinsLookupTableColumnDefs;
    rowData = pinModalInfo;
    defaultColDef.flex = 1;
  }
  let tableView = (
    <AgGridReact
      ref={gridRef}
      applyColumnDefOrder={true}
      columnDefs={columnDefs}
      components={{
        deleteIconRenderer: deleteIconRenderer,
        deletePinIconRenderer: deletePinIconRenderer,
        editIconRenderer: editIconRenderer,
        editPinIconRenderer: editPinIconRenderer,
      }}
      defaultColDef={defaultColDef}
      rowData={rowData}
    ></AgGridReact>
  );
  const closeAddPinModal = () => {
    setIsPinModalOpen(false);
    closeErrorBar();
    resetPinModal();
    setPinRowStId("");
  };
  const addPin = () => {
    setIsPinsAddMode(true);
    setIsPinModalOpen(true);
    resetPinModal();
  };
  const handlePlusIconClick = () => {
    if (activeTab === venue_page_constants.PIN_LOOKUP_TABLE_TAB) {
      addPin();
    } else if (activeTab === venue_page_constants.VENUES_TAB) {
      addVenue();
    }
  };
  const handleTabClick = (value) => {
    // resetting the column state to reset sorting and filter values of ag data grid
    if (gridRef.current) {
      gridRef.current.api.resetColumnState();
      gridRef.current.api.setFilterModel(null);
      gridRef.current.api.onFilterChanged();
    }
    setActiveTab(value);
  };

  const submitAddPinModal = (newPinData) => {
    onSubmitAddPinModal(newPinData, token);
    closeAddPinModal();
  };
  const updateAddPinModal = (newPinData) => {
    onUpdateAddPinModal(newPinData, token);
    closeAddPinModal();
  };

  const openErrorBar = (message) => {
    setErrorMessage(message);
    setShowErrorBar(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const closeErrorBar = () => {
    setShowErrorBar(false);
  };
  return (
    <>
      <div className="ct-container container">
        <Loader loading={loading} />
        {roleRedirect}
        {modal}
        {showErrorBar && (
          <NotificationBarComponent
            errorMessage={errorMessage}
            closeErrorBar={closeErrorBar}
            isError={true}
          ></NotificationBarComponent>
        )}
        {showVenueForm && (
          <>
            <Backdrop
              show={showVenueForm}
              clicked={() => setShowVenueForm(false)}
            />
            <VenueModal
              closeErrorBar={closeErrorBar}
              openErrorBar={openErrorBar}
              isAddVenue={isAddVenue}
              closeModal={closeModal}
              stName={studioName}
              stType={studioTypeName}
              stContacts={studioContactDetails}
              stIfb1Pin={stIfb1Pin}
              stIfb2Pin={stIfb2Pin}
              stPlPin={stPlPin}
              studioCost={studioHourRate}
              stLiveshotNotes={stLiveshotNote}
              stNotes={studioNotes}
              stDesc={stDesc}
              studioId={stId}
              venueStCity={stCity}
              venueStRegion={stState}
              venueStCountry={stCountry}
              stSkype={stSkype}
              stQuickLink={stQuickLink}
              stWebOther={stWebOther}
              stTxPath={stTxPath}
              stCreatedOn={studioCreatedOn}
              onDelete={deleteStudio}
              poNum={poNum}
              refreshFunction={onRefreshControlTracker}
            />
          </>
        )}

        <div className="righticons_wrapper">
          <NavigationHeader
            routerSignalDetailsPerm={true}
            controlTrackerPerm={true}
            venueAddressperm={true}
          />
        </div>
        <div className="venue-address-header-row">
          <div className="venue-address-header-banner">
            {venue_page_constants.BANNER_MESSAGE}
          </div>
          <div className="venue-address-header-title">
            <div className="venue-header-label">
              <img src={Venue_icon} alt="venue management icon" />
              <span>Venue Address Book</span>
            </div>
            <button
              title="Upload CSV"
              className="venue-upload-btn"
              onClick={handleClick}
            >
              <img src={Upload_icon} alt="upload icon" />
              <span>UPLOAD CSV</span>
              <input
                type="file"
                ref={hiddenFileInput}
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange}
              />
            </button>
            <button
              title="Save CSV"
              className="venue-download-btn"
              onClick={() => onDownloadvenueAddressDetails(token, activeTab)}
            >
              <img src={Download_icon} alt="download icon" />
              <span>SAVE CSV</span>
            </button>
            <button
              onClick={onRefreshControlTracker}
              className="venue-refresh-btn"
              title="Refresh"
            >
              <img src={Refresh_icon} alt="refresh icon" />
              <span>REFRESH</span>
            </button>
          </div>
        </div>
        <ConfirmationModal
          isModalOpen={isDeleteIconClicked}
          cancelDelete={cancelDelete}
          deleteRow={
            activeTab === venue_page_constants.PIN_LOOKUP_TABLE_TAB
              ? deletePinRow
              : deleteVenueRow
          }
          message={confirmationDeleteMessage}
        ></ConfirmationModal>

        {isPinModalOpen && (
          <>
            <Backdrop
              show={isPinModalOpen}
              clicked={() => setIsPinModalOpen(false)}
            />
            <AddPinsModal
              closeErrorBar={closeErrorBar}
              openErrorBar={openErrorBar}
              isModalOpen={isPinModalOpen}
              isPinsAddMode={isPinsAddMode}
              pinRowStId={pinRowStId}
              onAddPinModalSubmitClick={submitAddPinModal}
              onUpdatePinModalSubmitClick={updateAddPinModal}
              onAddPinModalCloseClick={closeAddPinModal}
              stLiveshotName={stLiveshotName}
              stIfb1Pin={stPinsTabIfb1Pin}
              stIfb2Pin={stPinsTabIfb2Pin}
              stPlPin={stPinsTabPlPin}
              stCreatedOn={pinsCreatedOn}
            ></AddPinsModal>
          </>
        )}

        <TabComponent
          tabs={Venue_Tabs_Array}
          activeTab={activeTab}
          onTabClick={handleTabClick}
          onPlusIconClick={handlePlusIconClick}
          isModalOpen={isPinModalOpen || showVenueForm || isDeleteIconClicked}
        />
        <div className="ag-theme-alpine-dark venuemgt_table_container">
          {tableView}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUserRole: state.user.currentUserRole,
    currentUser: state.auth.currentUser,
    showModal: state.venueAddressBook.showModal,
    signalMsg: state.venueAddressBook.signalMsg,
    venueAddressData: state.venueAddressBook.venueAddressInfo,
    venueAddressDetails: state.venueAddressBook.venueAddressInfo,
    fetchStudioTruckListSuccess:
      state.venueAddressBook.fetchStudioTruckListSuccess,
    pinModalInfo: state.routerSourceDestination.pinModalInfo,
    loading: state.routerSourceDestination.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetvenueAddressStatus: (token) =>
      dispatch(actions.getvenueAddressInfo(token)),
    onHideModal: () => dispatch(actions.hideModal()),
    onDeleteGrabbedSignal: (token, trackerId, userId) =>
      dispatch(actions.deleteGrabbedSignal(token, trackerId, userId)),
    onGetAllSIP: (token) => dispatch(actions.getAllSIP(token)),
    onFetchStudioTruckList: (token) =>
      dispatch(actions.fetchStudioTruckList(token)),
    onFetchStudioTruckTypes: (token) =>
      dispatch(actions.fetchStudioTruckTypes(token)),
    onSelectedStudioTruck: (token, stId, lsId, setPin = undefined) =>
      dispatch(actions.fetchStudioTruckData(token, stId, lsId, setPin)),
    onDownloadvenueAddressDetails: (token, activeTab) =>
      dispatch(actions.downloadVenueAddressDetails(token, activeTab)),
    onUploadvenueAddress: (token, file, activeTab, userId) =>
      dispatch(
        actions.uploadvenueAddressDetails(token, file, activeTab, userId)
      ),
    fetchPinModalInfo: (token) => dispatch(actions.getPinModalInfo(token)),
    onSubmitAddPinModal: (pinData, token) =>
      dispatch(actions.savePinModalData(pinData, token)),
    onUpdateAddPinModal: (pinData, token) =>
      dispatch(actions.updatePinModalData(pinData, token)),
    onDeletePinInfo: (token, pinId) =>
      dispatch(actions.deletePinsInfo(token, pinId)),
    onDeleteStudioTruck: (token, Id) =>
      dispatch(actions.deleteStudioTruck(token, Id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueAddressStatus);
